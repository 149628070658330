/* -------------------------fonts------------------------- */


@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');


@import url("../themeSettings/theme.css");

.inter{ font-family: "Inter", sans-serif; font-optical-sizing: auto; font-weight: 400; font-style: normal; }
.montserrat { font-family: "Montserrat", sans-serif; font-optical-sizing: auto; font-weight: 400; font-style: normal; }

.h1, h1 { font-family: "Inter", sans-serif !important; font-weight: 700 !important; font-size: 2.5rem; margin: 0 0 15px 0; padding: 0; }
.h2, h2 { font-family: "Inter", sans-serif !important; font-weight: 700 !important; font-size: 2rem; margin: 0 0 15px 0; padding: 0; }
.h3, h3 { font-family: "Inter", sans-serif !important; font-weight: 700 !important; font-size: 1.75rem; margin: 0 0 15px 0; padding: 0; }
.h4, h4 { font-family: "Inter", sans-serif !important; font-weight: 700 !important; font-size: 1.5rem; margin: 0 0 15px 0; padding: 0; }
.h5, h5 { font-family: "Inter", sans-serif !important; font-weight: 700 !important; font-size: 1.25rem; margin: 0 0 15px 0; padding: 0; }
.h6, h6 { font-family: "Inter", sans-serif !important; font-weight: 700 !important; font-size: 1.125rem; margin: 0 0 15px 0; padding: 0; }

body {font-size: 1.1rem !important; font-weight: 400 !important; background: var(--bgTheme) !important; inline-size: 100%; overflow-x: hidden;  overflow-y: auto !important; background: rgb(251, 255, 249) !important; background: -moz-linear-gradient(180deg, rgba(251, 255, 249, 1) 0%, rgba(243, 255, 232, 1) 100%) !important; background: -webkit-linear-gradient(180deg, rgba(251, 255, 249, 1) 0%, rgba(243, 255, 232, 1) 100%) !important; background: linear-gradient(180deg, rgba(251, 255, 249, 1) 0%, rgba(243, 255, 232, 1) 100%) !important; filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fbfff9", endColorstr="#f3ffe8", GradientType=1) !important;
  font-family: "Montserrat", sans-serif !important; }
p, li, .footer-contact-details ul li a, .footer-contact-details ul li span, .location span, .contact-page1 .form-layout-card label, .contact-page li .text-white, .main-serach .location-search label, .from-page label, .project-section1 .MuiTabs-flexContainer button{
  font-family: "Montserrat", sans-serif !important;  font-size: 1.1rem !important;}
.nav li a,
.nav li a span {  font-family: "Inter", sans-serif !important;
   font-weight: 600 !important; font-size: 22px; }

.font-300 { font-weight: 300 !important; }
.font-400 { font-weight: 400 !important; }
.font-500 { font-weight: 500 !important; }
.font-600 { font-weight: 600 !important; }
.font-700 { font-weight: 700 !important; }
.font-800 { font-weight: 800 !important; }

.lead { font-size: 18px !important; line-height: 30px !important; }
.font-13 { font-size: 13px !important; }
.font-14 { font-size: 14px !important; }
.font-15 { font-size: 15px !important; }
.btn-small { font-size: 12px !important; }

/* Colors */
:root { --clrDark: #222222; --clrLight: #ffffff; --clrWhite: #ffffff; --clrBlack: #000000;}

.bg-primary { background-color: var(--clrPrimary) !important; }
.bg-secondary { background-color: var(--clrSecondary) !important; }
.bg-tertiary { background-color: var(--clrTertiary) !important; }
.bg-dark { background-color: var(--clrDark) !important; }
.bg-grey { background-color: var(--clrGrey) !important; }
.bg-white { background-color: var(--clrWhite) !important; }
.text-white { color: var(--clrWhite) !important; }

.btn-primary,
.btn-secondary {
  background-color: var(--clrWhite) !important;
  color: var(--clrSecondaryButton) !important;
  border: 1px solid var(--clrSecondaryButton) !important;
  box-shadow: none !important;
  font-size: 16px !important;
  line-height: 20px !important;
  padding: 10px 30px !important;

  
}

.btn-primary { background: var(--clrPrimaryButton) !important; }
.btn-lg { font-size: 20px !important; line-height: 26px !important; padding: 15px 36px !important; }
.btn-tertiary { background-color: var(--clrTertiaryButton) !important; }
.btn-default { background-color: var(--clrDefaultButton) !important; }
.text-primary { color: var(--clrTextPrimary) !important; }
.text-secondary { color: var(--clrTextSecondary) !important; }
.text-tertiary { color: var(--clrTextTertiary) !important; }
.icon-primary { color: var(--clrIconPrimary) !important; }
.icon-secondary { color: var(--clrIconSecondary) !important; }
.border-color { color: var(--clrBorder) !important; background-color: var(--clrBorder) !important; }
.header-bg { background-color: var(--clrHeaderBackGroundColor) !important; }
.banner-bg { background-color: var(--clrHomeBannerColor) !important; }

/* Spaces */
section { padding: 4rem 0; }
.mb-50 { margin-block-end: 50px !important; }
.mt-30 { margin-block-start: 30px !important; }
.mb-100 { margin-block-end: 100px !important; }
.mb-0 { margin-block-end: 0; }
.pt-0 { padding-block-start: 0 !important; }
.pb-0 { padding-block-end: 0; }
.m-0 { margin: 0; }
.p-0 { padding: 0 !important; }
.p-30 { padding: 30px !important; }
.p-20 { padding: 20px !important; }
.mt-15 { margin-block-start: 15px !important; }
.p-15 { padding: 15px; }
.p-right { padding: 50px; }
.p-left { padding: 50px; }
.mb-15 { margin-block-end: 15px !important; }
.mb-30 { margin-block-end: 30px !important; }
.p-50 { padding: 50px !important; }
p { margin-block-end: 15px !important; margin-block-start: 0; }
.mx-15 { margin-inline-start: 15px !important; margin-inline-end: 15px !important; }
.m-15 { margin: 15px !important; }
.m-30 { margin: 30px !important; }
.p-100 { padding: 100px; }

/* Image */
.object-center-top { object-position: center top !important; background-position: center top !important; }

/* Text Align */
.text-center { text-align: center; }

/* Common */
.object-fit-cover { inline-size: 100%; block-size: 100%; object-fit: cover; object-position: center top; }
.border-radius { border-radius: 8px; }
.shadow { box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }
a { text-decoration: none !important; }
a:hover { text-decoration: none !important; }
.w-100 { inline-size: 100%; }
.h-100 { block-size: 100%; }
.mobile-show { display: none !important; }
.row { display: flex; flex-wrap: wrap; margin-inline-end: -12px; margin-inline-start: -12px; }
.col-md-4 { flex: 0 0 33.3333333333%; max-inline-size: 33.3333333333%; padding-inline-start: 12px; padding-inline-end: 12px; }
.pb-30 { padding-block-end: 30px; }

/* Transitions */
.transction, .value-item, .why-about-company .statistics-card, .statistics-item, .floating-button, .social-icons a, .logo, .header-top, .nav, .nav li a, .nav li a span, .logo::before, .feat-carc-list .custom-card-content, .feat-carc-list .btn-secondary, .MuiButton-contained, .btn, button, .shadow-hover-efftect, .custom-check-box label, .home-why-group .shadow-hover-efftect .btn-primary svg, .our-features .our-feature-card .feature-pic img, .footer-list li a, .footer-list li a svg, .footer-list li a, .top-animation, .premium-features .features-card, .btn-arrow-efftect, .btn-arrow-efftect svg, .feature-pic  {
  -moz-transition: all 0.5s ease-in-out !important; -ms-transition: all 0.5s ease-in-out !important; -o-transition: all 0.5s ease-in-out !important; transition: all 0.5s ease-in-out !important;}

.our-feature-card { position: relative; text-align: start; }
.our-feature-card .custom-card-content { background: #eee; text-align: center; padding: 50px !important; position: relative; }
.our-feature-card .custom-card-content::before { content: ""; position: absolute; inset-inline-start: 50%; inset-block-start: -40px; margin-inline-start: -40px; z-index: 1; inline-size: 80px; block-size: 80px; background: #fff; border-radius: 100%; box-shadow: 0px 0px 10px #f1f1f1; }
.filter-block { margin-block-start: 60px; }
.custom-card { background: #ffffff !important; padding: 15px; border-radius: 12px !important; }
.prebooking-card { padding: 5px; border-radius: 10px !important; }
.card-actions { text-align: center; display: block !important; }
.btn-arrow { padding: 0 !important; background: none !important; border: none !important; }
.our-feature-card .custom-card-content h6 { color: #000 !important; }
.our-feature-card .custom-card-content p,
.our-feature-card .custom-card-content span { color: #000; }
.our-feature-card .card { background: none !important; box-shadow: none !important; }
.feat-carc-list .custom-card-content { margin: 15px 15px 15px 15px; background: #f4ffea !important; border: solid 1px #d6f6bb; }
.feat-carc-list:hover .btn-secondary { background: var(--clrSecondaryButton) !important; color: #fff !important; }
.feat-carc-list:hover .custom-card-content { box-shadow: 0px 0px 15px #dad4d4 !important; }
.feat-carc-list.active .custom-card-content::before { box-shadow: none !important; }
.feature-pic { inline-size: 50px; display: block; margin: 0 auto 0px auto; z-index: 9; position: relative; inset-block-start: 45px; }
.btn-white { background: var(--clrWhite) !important; color: var(--clrTextPrimary) !important; }
.no-shadow { box-shadow: none !important; }
.product-pic { position: relative; block-size: 280px; overflow: hidden; }
.product-pic img { object-fit: cover; inline-size: 100%; block-size: 100%; object-position: top center; }
.product-pic figcaption { position: absolute; inset-inline-start: 5px; inset-block-start: 10px; border-radius: 10px; color: var(--clrWhite); padding: 5px 15px; font-size: 12px !important; }
.product-pic .ongoing,
.product-pic .upcoming { position: absolute; inset-inline-start: 5px; inset-block-start: 10px; border-radius: 10px; color: var(--clrWhite); background: var(--clrSecondaryButton); padding: 5px 15px; font-size: 12px !important; }
.product-pic .completed { position: absolute; inset-inline-start: 5px; inset-block-start: 10px; border-radius: 10px; color: var(--clrWhite); padding: 5px 15px; font-size: 12px !important; }
.search-result-page { margin: 10px 100px; }
.custom-padding { padding: 100px; }
.card-title { position: relative; padding-block-end: 5px; margin-block-end: 30px !important; color: #000; }
.project-card { box-shadow: none !important; }
.project-page-cards .project-card { box-shadow: none !important; inline-size: 100% !important; padding: 0 !important; }
.custom-card-width { min-inline-size: 300px; overflow: hidden; }

/* Loader */
.loader-wrapper { position: fixed; inset-block-start: 0; inset-inline-start: 0; inline-size: 100vw; block-size: 100vh; background-color: white; display: flex; justify-content: center; align-items: center; z-index: 9999; }
.loader { display: flex; justify-content: center; align-items: center; position: fixed; inset-block-start: 50%; inset-inline-start: 50%; transform: translate(-50%, -50%); inline-size: 90px; block-size: 103px; z-index: 9999; }
.loader div { position: absolute; inline-size: 50px; block-size: 31px; }
.rot { transform: rotate(150deg); }
.rot2 { transform: rotate(20deg); }
.loader div:nth-of-type(2) { transform: rotate(60deg); }
.loader div:nth-of-type(3) { transform: rotate(-60deg); }
.loader div div { inline-size: 100%; block-size: 100%; position: relative; }
.loader div div span { position: absolute; inline-size: 4px; block-size: 0%; background: #053146; z-index: 999999; }
.h11 { inset-inline-start: 0; animation: load1 3.2s ease infinite; }
.h21 { inset-inline-end: 0; animation: load2 3.2s ease 0.4s infinite; }
.h31 { inset-inline-end: 0; animation: load3 3.2s ease 0.8s infinite; }
.h41 { inset-block-start: 10px; inset-inline-start: 23px; animation: load4 3.2s ease 1s infinite; transform: rotate(90deg); }
.h51 { inset-block-end: 0; animation: load5 3.2s ease 1.2s infinite; }
.h61 { inset-inline-start: 0; animation: load6 3.2s ease 1.3s infinite; }
.mb-50 { margin-block-end: 50px !important; }

@keyframes load1 {
  0% { inset-block-end: 0; block-size: 0; }
  6.944444444% { inset-block-end: 0; block-size: 100%; }
  50% { inset-block-start: 0; block-size: 100%; }
  59.944444433% { inset-block-start: 0; block-size: 0; }
}

@keyframes load2 {
  0% { inset-block-start: 0; block-size: 0; }
  6.944444444% { inset-block-start: 0; block-size: 100%; }
  50% { inset-block-end: 0; block-size: 100%; }
  59.944444433% { inset-block-end: 0; block-size: 0; }
}

@keyframes load3 {
  0% { inset-block-start: 0; block-size: 0; }
  6.944444444% { inset-block-start: 0; block-size: 100%; }
  50% { inset-block-end: 0; block-size: 100%; }
  59.94444443% { inset-block-end: 0; block-size: 0; }
}

@keyframes load4 {
  0% { inset-block-start: 37px; inset-inline-start: 23px; block-size: 134%; }
  6.944444444% { inset-block-start: 10px; block-size: 134%; }
  50% { inset-block-end: 10px; block-size: 134%; }
  59.94444443% { inset-block-end: 0; block-size: 0; }
}

@keyframes load5 {
  0% { inset-block-end: 0; block-size: 0; }
  6.944444444% { inset-block-end: 0; block-size: 100%; }
  50% { inset-block-start: 0; block-size: 100%; }
  59.94444443% { inset-block-start: 0; block-size: 0; }
}

@keyframes load6 {
  0% { inset-block-end: 0; block-size: 0; }
  6.944444444% { inset-block-end: 0; block-size: 100%; }
  50% { inset-block-start: 0; block-size: 100%; }
  59.94444443% { inset-block-start: 0; block-size: 0; }
}

.loader11 { inline-size: 45px; aspect-ratio: .75; --c: no-repeat linear-gradient(#000 0 0); background: var(--c) 0% 50%, var(--c) 50% 50%, var(--c) 100% 50%; background-size: calc(100%/3) 50%; animation: l7 1s infinite linear alternate; }

@keyframes l7 {
  0% { background-size: 20% 50%, 20% 50%, 20% 50%; }
  20% { background-size: 20% 20%, 20% 50%, 20% 50%; }
  40% { background-size: 20% 100%, 20% 20%, 20% 50%; }
  60% { background-size: 20% 50%, 20% 100%, 20% 20%; }
  80% { background-size: 20% 50%, 20% 50%, 20% 100%; }
  100% { background-size: 20% 50%, 20% 50%, 20% 50%; }
}
.customhover {
  transition: transform 0.3s ease-in-out;
}

.customhover:hover {
  transform: scale(1.04);
}

.shadow-pic { padding: 0; margin: 0; position: relative; border-radius: 8px; }
.shadow-pic img { inline-size: 100%; display: block; border-radius: 8px; }
.project-section { position: relative; }
.custom-block img { inline-size: 100%; block-size: 100%; display: block; object-fit: cover; margin: 0; padding: 0; }
.custom-block .figure { margin: 0 auto; padding: 0; position: relative; block-size: 500px; border-radius: 12px; overflow: hidden; }
.custom-block .figure,
.custom-block .lead { inline-size: 900px; margin: 0 auto; }
.custom-block .figure::after { content: ""; position: absolute; inset-inline-start: 0; inset-block-start: 0; inline-size: 100%; block-size: 100%; background: rgba(0, 0, 0, 0.65); z-index: 1; }
.custom-block .figure svg { position: absolute; inset-inline-start: 50%; inset-block-start: 50%; inline-size: 100px; block-size: 100px; color: #e3301e; margin-inline-start: -50px; margin-block-start: -50px; z-index: 3; }
.custom-block .figure::before { content: ""; position: absolute; inset-inline-start: 50%; inset-block-start: 50%; inline-size: 30px; block-size: 30px; background: var(--clrWhite); margin-block-start: -16px; margin-inline-start: -17px; z-index: 2; }
.testimonial-card { max-inline-size: 900px; margin: 0 auto; }
.testimonial-card svg { inline-size: 70px; block-size: 100px; color: var(--clrPrimary); }
.testimonial-card .testimonial-title { margin-block-end: 30px; }
.testimonial-card .testimonial-title span { display: block; color: #000; font-weight: 400; }
.statistics { position: relative; z-index: 2; background: #fff; }
.statistics .custom-card { position: relative; }
.statistics-pic { inline-size: 75px; block-size: 75px; border: solid 1px #ccc; margin: 0 auto 15px auto; padding: 5px; border-radius: 100%; }
.statistics-pic svg { inline-size: 50px; block-size: 75px; color: var(--clrSecondary); }
.statistics-pic img { inline-size: 90%; block-size: 90%; position: relative; inset-block-start: 3px; }
.blog-card { position: relative; border: solid 1px #ccc; }
.date { border-block-end: solid 1px #ccc; padding-block-start: 15px; margin-block-end: 15px; }
.date p { font-size: 15px; display: inline-block; vertical-align: top; }
.date svg { display: inline-block; inline-size: 20px; vertical-align: top; margin-inline-end: 15px; color: var(--clrPrimary); }
.blog-card .card-actions { text-align: start !important; }
.inner-banner { padding: 15px 0 0 0; background: var(--clrTextPrimary) !important; }
.inner-banner ol { color: #fff; }
.inner-banner ol li { color: #fff; }
.inner-banner ol li a { color: #fff; }
.project-banner { position: relative; padding: 0; inline-size: 100%; margin-block-start: 95px; }
.project-lists { inline-size: 100%; }
.project-lists .project-card { margin: 0 0 20px 0 !important; }
.project-list-left { inline-size: 100% !important; box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.175) !important; max-inline-size: 300px !important; block-size: 100%; }
.project-list-left button { text-align: start !important; font-size: 18px !important; font-weight: 700; align-items: self-start !important; border-block-end: solid 1px #ccc; position: relative; color: #000 !important; }
.project-list-left button.Mui-selected { background: var(--clrTextPrimary); color: var(--clrWhite) !important; }
.detail-title { padding-block-end: 10px; }
.youtube-image { position: relative; }
.youtube-image::before { content: ""; position: absolute; inset-inline-start: 50%; inset-block-start: 50%; margin: -17px 0 0 -22px; inline-size: 45px; block-size: 34px; background: #fff; border-radius: 20px; }
.youtube-image svg { inline-size: 60px; block-size: 60px; color: var(--clrPrimary); position: absolute; inset-inline-start: 50%; inset-block-start: 50%; margin: -30px 0 0 -30px; border-radius: 10px; }
.subtile { font-weight: 700 !important; color: var(--clrTextPrimary); margin-block-end: 10px !important; }
.map-iframe iframe { block-size: 295px; }
.content-block { background: var(--clrGrey); margin-block-end: 15px; padding: 10px; font-weight: bold; font-size: 13px; }
.content-block svg { display: inline-block; color: var(--clrPrimary); block-size: 20px; position: relative; inset-block-start: 5px; margin-inline-end: 5px; }
.text-inner { max-inline-size: 800px; margin: 0 auto; }
.property-image { inline-size: 100%; block-size: 600px; }
.project-gallery-list { position: relative; }
.project-gallery-list .project-gallery-list-item { block-size: 260px; }
.project-gallery-list .project-gallery-list-item img { inline-size: 100%; block-size: 100%; object-fit: cover; object-position: center top; border: solid 1px #ccc; }
.blog-pic { position: relative; block-size: 300px; overflow: hidden; margin: 0; padding: 0; }
.blog-pic img { inline-size: 100%; block-size: 100%; object-fit: cover; object-position: center top; }
.related-blogs-lists { position: relative; border-block-end: solid 1px #ccc; padding-inline-start: 100px; }
.related-blogs-lists img { inline-size: 60px; position: absolute; inset-inline-start: 0; inset-block-start: 0; }
.related-blogs-lists .date { border: none !important; }
.date-of-visit { padding-block-start: 0px !important; }
.nri-list { margin: 30px 0 0 0; padding: 0; position: relative; list-style: none; }
.nri-list li { display: inline-block; max-inline-size: 30%; border: solid 1px #ccc; padding: 30px; margin: 0 15px 30px 15px; text-align: center; position: relative; vertical-align: top; min-block-size: 340px; }
.nri-list li::before { content: ""; position: absolute; inset-inline-start: 0; inset-block-start: 0; inline-size: 0; block-size: 0; border-block-start: 40px solid var(--clrTextPrimary); border-inline-end: 40px solid transparent; }
.nri-list li::after { content: ""; position: absolute; inset-inline-end: 0; inset-block-end: 0; inline-size: 0; block-size: 0; border-block-end: 40px solid var(--clrTextPrimary); border-inline-start: 40px solid transparent; }
.nri-list li p { font-size: 14px; }
.nri-list li:last-child { max-inline-size: 62%; }
.nri-items { background-color: var(--clrGrey); }
.nri-feature { padding: 10px; }
.feature-page .custom-card { padding-inline-start: 100px; }
.feature-page .feature-pic1 { position: absolute; inset-inline-start: 0; inset-block-start: 25px; }
.form-card { position: relative; padding: 50px; }
.form-card::before { content: ""; position: absolute; inset-inline-start: 0; inset-block-start: 0; inline-size: 0; block-size: 0; border-block-start: 50px solid var(--clrTextPrimary); border-inline-end: 50px solid transparent; z-index: 1; }
.form-card::after { content: ""; position: absolute; inset-inline-end: 0; inset-block-end: 0; inline-size: 0; block-size: 0; border-block-end: 50px solid var(--clrTextPrimary); border-inline-start: 50px solid transparent; }
.contact-page li .text-white { color: var(--clrBlack) !important; }
.sub-title { font-weight: 700; color: var(--clrTextPrimary); border-block-end: solid 1px #ccc; padding-block-end: 20px; }
.youtube-image { block-size: 300px; }
.youtube-image img { inline-size: 100%; block-size: 100%; object-fit: cover; object-position: center; }
.scrolling-down .floating-button { inset-inline-end: -125px; }
.scrolling-down .floating-button:hover { inset-inline-end: 0px; }
.features-block { margin-block-start: 30px; }
.features-block h4 { text-align: center; margin-block-end: 30px; margin-block-start: 10px; }
.features-block h2 { margin-inline-start: 100px; font-weight: bolder; }
.features-card { background: #ffffff !important; padding: 15px; border-radius: 12px !important; margin-block-end: 10px; box-shadow: 0 0.5rem 0.5rem rgba(105, 105, 105, 0.175) !important; }
.features-card p { text-align: center; }
.features-card h6 { text-align: center; }
.features-block figure { padding: 15px; box-shadow: 0 0.5rem 0.5rem rgba(105, 105, 105, 0.175); border-radius: 20px; background-color: var(--clrGrey); }
.features-block img { max-inline-size: 100%; block-size: auto; object-fit: cover; border-radius: 10px; }
.features-block .row { display: flex; align-items: center; justify-content: center; padding: 0px 20px; }
.features-block .features-ul { display: flex; justify-content: center; }
.features-list-block { background-color: #ccc; }
.features-block ul { list-style-type: none; inline-size: 100%; }
.custom-align { text-align: center; }
.features-block span { background-color: var(--clrTextPrimary); color: #ffffff; padding: 7px; inset-block-end: 35px; position: relative; }
.capitalize { text-transform: capitalize; }
.custom-space { margin-block-start: 20px; }
.main-serach { position: relative; z-index: 2; border-block-end: solid 1px #f3eaea; border-block-start: solid 1px #f3eaea; }
.main-serach .location-search { position: relative; overflow: visible; padding-block-start: 50px; border: solid 1px #eee; }
.main-serach .search-title { position: absolute; inset-inline-start: 20px; inset-block-start: -18px; background: var(--clrTextPrimary); color: var(--clrWhite) !important; padding: 10px 15px; border-radius: 8px; font-size: 18px; }
.main-serach .search-item { margin-block-end: 10px; }
.main-serach .location-search .btn-primary { padding: 10px 0; border-radius: 4px; font-size: 20px; position: relative; inset-block-start: -1px; }
.main-serach .location-search label,
.from-page label { background: #fff; padding: 3px 7px; }
.welcome-pic { position: relative; }
.welcome-pic .pic1 { inline-size: 90%; margin-block-end: 150px; display: block; }
.welcome-pic .pic2 { inline-size: 40%; position: absolute; inset-block-end: -70px; inset-inline-end: 50px; }
.welcome-content h2 { font-size: 60px !important; line-height: 64px !important; color: var(--clrTextPrimary); }
.welcome-content div,
.welcome-content p { font-size: 18px; line-height: 30px !important; }
.service_text { margin-block-end: 30px; }
.project-heading { font-size: 40px !important; line-height: 40px !important; margin-block-end: 30px !important; }
.project-heading a { color: var(--clrTextPrimary) !important; }
.icon-with-text { position: relative; border-block-end: solid 1px #eee; padding-block-end: 10px; margin-block-end: 10px; }
.icon-with-text svg,
.icon-with-text p { display: inline-block; vertical-align: top; }
.icon-with-text svg { margin-inline-end: 15px; color: #000000; }
.project-grid { position: relative; padding-block-end: 100px; margin-block-end: 50px; }
.project-grid::before { content: ""; inline-size: 100%; block-size: 70px; position: absolute; inset-block-end: 0; inset-inline-start: 0; background: url("../images//project-divder.svg") no-repeat center center; background-size: contain !important; }
.project-grid:last-child::before { display: none; padding-block-end: 0 !important; margin-block-end: 0 !important; }
.project-grid:last-child { padding-block-end: 0px; margin-block-end: 0px; }
.slider-inner figure { inline-size: 100%; margin: 0; padding: 0; block-size: 500px; overflow: hidden; }
.slider-inner figure img { inline-size: 96%; block-size: 100%; object-fit: cover; object-position: center top; }
.slider-inner .slick-center.slick-current figure { block-size: 700px; }
.project-slider .slick-slide { float: none !important; display: inline-block; vertical-align: middle; }
.project-slider .slick-dots { margin-block-start: 30px !important; }
.slick-dots { inset-block-end: -50px !important; }
.slick-dots li button:before { font-size: 20px !important; }
.slick-dots li.slick-active button:before { color: var(--clrPrimary) !important; }
.footer-contact-details ul li a,
.footer-contact-details ul li span { color: var(--clrBlack) !important; }
.footer-contact-details li a { padding-inline-start: 0 !important; }
.footer .social-icons a { background: var(--clrIconPrimary); color: #ffffff !important; }
.footer .social-icons a svg { color: #ffffff !important; fill: var(--clrWhite) !important; }
.amenities-block { margin-block-start: 30px; }
.amenities-block ul li { inline-size: 44%; display: inline-block; vertical-align: top; }
.amenities-block ul li .list-item-icon { min-inline-size: 40px; position: relative; inset-block-start: 3px; }
.list-item-icon svg { color: #000000; }
.border-bottom { border-block-end: solid 1px var(--clrSecondaryButton); }
.border-top { border-block-start: solid 1px var(--clrSecondaryButton); }
.py-10 { padding: 10px 0px 10px 0px; }
.px-10 { padding: 0px 10px; }
.font-size-80 { font-size: 80px; }
.border-right { border-inline-end: solid 1px var(--clrSecondaryButton); }
.jc-center { justify-content: center; }
.highlights h2 { text-align: center; color: #fff; }
.highlights-img { inline-size: 150px; block-size: auto; }
.center-align { display: flex; justify-content: center; align-items: center; color: #fff; }
.blog-card p { color: #000; }
.blog-card .date svg { margin-inline-end: 4px; position: relative; inset-block-start: -8px; }
.aboutus .tabs { display: flex; gap: 20px; justify-content: center; margin-block-end: 30px; }
.aboutus .tab { text-decoration: none; color: red; font-weight: bold; }
.aboutus { text-align: start; }
.aboutus h2 { text-align: start; margin-block-end: 30px; font-weight: bold; color: var(--clrTextPrimary) !important; }
.aboutus .vision-mission h2,
.aboutus .about-company h2 { text-align: center; }
.text-content { flex: 1; text-align: start; }
.image-content { flex: 1; text-align: end; }
.image-content img { max-inline-size: 100%; block-size: 80%; }
#vision-mission { text-align: center; }
.image-gallery { display: flex; justify-content: center; margin: 30px; }
.image-item img { inline-size: 100%; max-inline-size: 300px; }
.image-item h3 { font-size: 24px; margin-block-start: 10px; text-align: center; }
.image-item p { font-size: 16px; text-align: center; }
.choose p { font-size: 16px; margin: 20px; padding: 0px 0px 20px 30px; text-align: start; }
.value-item { display: flex; flex-direction: column; align-items: center; background-color: rgb(255, 255, 255); transition: 0.3s; border-width: 1px; border-style: solid; border-color: rgb(228, 228, 228); border-image: initial; border-radius: 16px; text-align: center; cursor: pointer; min-block-size: 350px; justify-content: center; }
.value-item:hover { box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 30px; border-color: transparent; }
.value-item img { inline-size: 100px; margin-block-end: 8px; margin-block-start: 5px; display: block; transition: 0.8s; }
.value-item:hover img { transform: rotateY(360deg); }
.value-item h5 { margin-block-end: 2px; font-size: 1.1rem; font-weight: bold; color: #333; }
.custom-width-80 { inline-size: 80%; margin: 0 auto; display: flex; justify-content: center; align-items: center; }
.value-item p { margin: 0; padding: 5px 25px; text-align: center; color: rgb(0, 0, 0); font-size: 0.95rem; }
.red-line { inline-size: 100px; block-size: 3px; background-color: red; border: none; margin-block-end: 20px; }
.blog-card-content { padding: 20px 20px 0 20px !important; }
.blog-card .card-actions { padding: 20px !important; }
.project-section-new { padding: 60px 0 150px 0; position: relative; }
.project-section-new::before { content: ""; position: absolute; inset-inline-start: 0; inset-block-start: 0; inline-size: 100%; block-size: 30%; background: var(--clrPrimary); z-index: -1; }
.project-section-new h2 { color: #ffffff !important; text-shadow: 0px 3px 13px rgba(0, 0, 0, 0.1); margin-block-end: 50px; }
.statistics-block { margin-block-end: 100px; }
.statistics-ul { margin: 0; padding: 0; list-style: none; text-align: center; }
.statistics-ul li { display: inline-block; vertical-align: top; border-inline-end: solid 1px #fff; padding: 0 40px; font-size: 30px; font-weight: 700; color: var(--clrWhite) !important; text-shadow: 0px 3px 13px rgba(0, 0, 0, 0.1); }
.statistics-ul li:last-child { border: none; }
.statistics-ul li span { display: block; font-size: 18px; }
.project-section .features-block .row { justify-content: start; align-items: start; }
.project-section .features-block .shadow { box-shadow: none !important; min-block-size: 520px; }
.project-section .features-block img { inline-size: 100%; block-size: 400px; display: block; margin-block-end: 30px; }
.journey-of-hightlights .features-block { justify-content: center; display: block; }
.journey-of-hightlights .features-block figure { inline-size: 100%; padding: 0; box-shadow: none; }
.journey-of-hightlights .features-block img { inline-size: 100%; }
.journey-of-hightlights .features-block h2 { text-align: center; padding: 0 0 50px 0; color: var(--clrTextPrimary) !important; }
.journey-of-hightlights .features-block ul { margin: 0; padding: 0; }
.journey-of-hightlights .features-block ul li { position: relative; padding: 0 0 0 30px; margin-block-end: 15px; }
.journey-of-hightlights .features-block ul li::before { content: ""; inline-size: 15px; block-size: 15px; background: var(--clrTextPrimary); position: absolute; inset-inline-start: 0; inset-block-start: 5px; border-radius: 100%; }
.journey-of-hightlights .features-block ul li::after { content: ""; position: absolute; inset-inline-start: 5px; inset-block-start: 6px; transform: rotate(45deg); block-size: 10px; inline-size: 6px; border-block-end: 2px solid #ffffff; border-inline-end: 2px solid #ffffff; }
.journey-of-hightlights .features-block .row,
.healthy-lifestyle .features-block .row { align-items: end; }
.project-logo { display: flex; justify-content: center; align-items: center; }
.project-logo img { inline-size: 100%; border-radius: 15px; }
.project-video-section-grid-right,
.project-video-section-grid-left { padding: 50px; }
.project-video-section .custom-card { box-shadow: none !important; background: none !important; }
.project-video-section .form-card::before,
.project-video-section .form-card::after { display: none; }
.project-video-section .MuiTextField-root,
.project-video-section .MuiFormControl-fullWidth { background: var(--clrWhite); }
.project-video-section button { background: var(--clrWhite) !important; color: var(--clrPrimary) !important; }
.project-video-section-grid { background: var(--clrTextPrimary); }
.project-section1 .MuiTabs-flexContainer { background: var(--clrGrey); }
.project-section1 .MuiTabs-flexContainer button { font-size: 20px; position: relative; }
.project-section1 .MuiTabs-flexContainer button::before { content: ""; position: absolute; inset-inline-end: 0; inset-block-start: 0; inline-size: 1px; block-size: 50px; }
.project-section1 .MuiTabs-flexContainer button:last-child::before { display: none !important; }
.project-section1 .MuiTabs-flexContainer button.Mui-selected::before { display: none; }
.project-section1 .css-1vdtubk-MuiTabs-root .MuiTab-root:not(.Mui-selected)::before { display: block; }
.project-section1 .css-1vdtubk-MuiTabs-root .Mui-selected { font-weight: 700; }
.project-section1 .css-1vdtubk-MuiTabs-root .Mui-selected::before { display: none !important; }
.project-section1 .product-pic { block-size: 400px !important; }
.product-pic img { inline-size: 100%; object-fit: cover; }
.features-list { margin: 30px 0 0 0; padding: 0; position: relative; list-style: none; justify-content: center; }
.features-list li { display: inline-block; max-inline-size: 30%; min-inline-size: 30%; border: solid 1px #ccc; padding: 30px; margin: 0 15px 30px 15px; text-align: center; position: relative; vertical-align: top; align-items: center; min-block-size: 200px; }
.features-list li::before { content: ""; position: absolute; inset-inline-start: 0; inset-block-start: 0; inline-size: 0; block-size: 0; border-block-start: 40px solid var(--clrTextPrimary); border-inline-end: 40px solid transparent; }
.nri-img { border-radius: 30px; }
.features-list li::after { content: ""; position: absolute; inset-inline-end: 0; inset-block-end: 0; inline-size: 0; block-size: 0; border-block-end: 40px solid var(--clrTextPrimary); border-inline-start: 40px solid transparent; }
.features-list li p { font-size: 14px; }
.custom-list-li { margin: 0; padding: 0; list-style: none; }
.custom-list-li li { margin-block-end: 15px; padding-inline-start: 40px; position: relative; }
.custom-list-li li::before { content: ""; inline-size: 15px; block-size: 15px; background: var(--clrTextPrimary); position: absolute; inset-inline-start: 0; inset-block-start: 5px; border-radius: 100%; }
.custom-list-li li::after { content: ""; position: absolute; inset-inline-start: 5px; inset-block-start: 6px; transform: rotate(45deg); block-size: 10px; inline-size: 6px; border-block-end: 2px solid #ffffff; border-inline-end: 2px solid #ffffff; }
.compelte-projects { padding-block-start: 0; }
.compelte-projects .project-categories { margin-block-start: 20px !important; margin-block-end: 50px !important; padding: 15px; border-radius: 12px !important; }
.compelte-projects .project-categories .MuiTabs-flexContainer { text-align: center; display: block; }
.compelte-projects .project-categories .MuiTabs-flexContainer button { display: inline-block; }
.compelte-projects .project-categories .MuiTabs-indicator { display: none !important; }
.location { color: #000; }
.custom-color { color: var(--clrSecondaryButton); }
.custom-card a svg { color: var(--clrPrimary); }
.custom-row { display: flex; justify-content: center; }
.faq-span { color: black; }
.about-us-content { margin-inline-start: 120px; }
.whatsapp-button svg { font-size: 40px; }
.foote-bg { position: relative; z-index: -1; }
.faq-accordion { box-shadow: none !important; padding: 2px; border: 1px solid #f1f1f1; }
.career-icon { position: relative; inset-block-start: 5px; color: var(--clrIconSecondary); }
.contact-card { background: #ffffff !important; border: 1px solid #e7e7e7; padding: 15px; border-radius: 12px !important; }
.contact-description { display: flex; justify-content: center; margin: 30px 30px; }
.form-layout-card { background: none !important; }
.gallery-icon svg { font-size: 60px; color: var(--clrSecondary); }
.media-tabs { justify-content: center; display: flex; }
.css-1gynfha-MuiButtonBase-root-MuiTab-root.Mui-selected { color: #ffffff !important; }
.styles-module_chatHeader__gaeOA::before { background: none !important; z-index: -1; }
.testimonials-card { background-color: #ffffff !important; }
.test-card { position: relative; }
.achieve-row { display: flex; flex-wrap: wrap; align-items: center; }
.achieve-row:first-child { order: 1; }
.achieve-row:last-child { order: 2; }
.core-values { padding-block-end: 30px; }
.core-values-content { display: flex; justify-content: center; }
.css-11waxt7-MuiGrid-root > .MuiGrid-item { padding-block-start: 0px !important; margin-inline-end: -200px; }
.testimonial-quote { color: var(--clrPrimary); }
.about-company { inline-size: 90%; margin: 0 auto; }
.company-block .row { display: flex; align-items: center; justify-content: center; padding: 0px 20px; margin-block-start: 30px; }
.company-block img { max-inline-size: 100%; block-size: auto; object-fit: cover; border-radius: 10px; }
svg.site-title { font-family: "Russo One", sans-serif; inline-size: 100%; block-size: 300px; }
svg.site-title text { text-transform: uppercase; animation: stroke 5s infinite alternate; stroke-width: 2; stroke: var(--clrTextPrimary); font-size: 140px; }

@keyframes stroke {
  0% { fill: rgba(72, 138, 20, 1); stroke: rgb(54, 160, 121); stroke-dashoffset: 25%; stroke-dasharray: 0 50%; stroke-width: 2; }
  100% { fill: rgba(72, 138, 20, 0); stroke: rgb(0, 169, 17); }
  80% { fill: rgba(72, 138, 20, 0); stroke: rgb(0, 161, 30); stroke-width: 3; }
  100% { fill: var(--clrTextPrimary); stroke: rgba(54, 95, 160, 1); stroke-dashoffset: -25%; stroke-dasharray: 50% 0; stroke-width: 1; }
}

.chairman-block { background: #fff; }
.our-features { border-block-end: solid 1px #f3eaea; }
.our-features .our-feature-card:hover .feature-pic img{transform: scale(1.03);}
.mx-5 { margin-inline-start: 15px; margin-inline-end: 15px; }
.project-grid figure { margin-inline-end: 30px; position: relative; overflow: hidden; }
.project-grid figure img { inline-size: 100%; object-position: center top; }
.project-grid:nth-child(even) figure { margin-inline-end: 0; margin-inline-start: 30px; }
.project-image-inner figure figcaption { background: #e3301e; position: absolute; inset-inline-start: 10px; inset-block-start: 10px; border-radius: 12px; padding: 15px 30px; color: #fff !important; font-weight: bold; font-size: 16px !important; }
.project-image-inner figure figcaption p { color: #fff !important; font-weight: bold; font-size: 16px !important; margin-block-end: 0 !important; }
.custom-block { background: #fff; }
.testimonial-section { background: #fff; }
.vision-mission { background: #fff; margin: 30px 0; padding: 50px; position: relative; }
.vision-mission::before { content: ""; position: absolute; inset-inline-start: 100%; inset-block-start: 0; inline-size: 100%; block-size: 100%; background: #fff; }
.vision-mission::after { content: ""; position: absolute; inset-inline-end: 100%; inset-block-start: 0; inline-size: 100%; block-size: 100%; background: #fff; }
.frequently-asked-questions { background: #fff; }
.custom-check-box { position: relative; margin-inline-end: 10px; margin-block-end: 10px; }
.custom-check-box input { position: absolute; inset-inline-start: 0; inset-block-start: 0; opacity: 1; inline-size: 1px; block-size: 1px; }
.custom-check-box label { background: #c9d2c1; display: block; padding: 5px 15px; border-radius: 10px; cursor: pointer; color: var(--clrSecondaryButton) !important; font-weight: 600; }
.custom-check-box:hover label { background-color: var(--clrSecondaryButton); color:#fff !important; }
.custom-check-box input:checked ~ label { background: #e3301e; color: #ffffff !important; }
.products-search { padding-block-start: 15px; border-block-end: solid 1px #ccc; padding-block-end: 20px; margin-block-end: 30px; }
.search-top { position: relative; }
.filter-items { justify-content: center; }
.search-bottom h6 { margin-block-end: 10px; }
.prebooking-card { position: relative; }
.prebooking-card p { margin: 0 !important; padding: 0 0 5px 0; font-weight: 600; }
.location-section { position: relative; }
.location-section button { position: absolute; inset-inline-end: 1px; inset-block-start: 1px; padding: 8px 25px !important; font-size: 14px !important; }
.location-section .search-text-field { border-radius: 100px !important; }
.project-page-cards .custom-card-width { max-inline-size: 100% !important; }
.new-project-card { position: relative; border: solid 1px #ccc; }
.arrow-btn { position: absolute; inset-inline-end: 10px; inset-block-end: 5px; }
.testimonials-card { min-block-size: 300px; }
.project-categories-tabs .cat-btn { border-radius: 15px; font-weight: 600; border: solid 1px #fff; margin: 0 5px; padding: 10px 20px !important; min-block-size: 1px !important; color: #fff !important; }
.project-categories-tabs .btn-primary { color: #000 !important; }
.project-categories-tabs .cat-btn.Mui-selected { background: #fff; color: var(--clrSecondaryButton) !important; }
.project-categories-tabs .MuiTabs-indicator { display: none !important; }
.video-item button { background: none; }
.video-item svg { inline-size: 70px; block-size: 50px; color: red; }
.welcome-block { inline-size: 100%; overflow: hidden; }
.mob-view { display: none; }
.MuiButton-contained:hover { box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.09) !important; }
.css-10o2lyd-MuiStack-root { padding-block-start: 0px !important; }
.css-10o2lyd-MuiStack-root:hover { padding-block-start: 5px !important; }
.ps-list-right { margin-inline-start: 50px; }
.form-control { padding: 0; margin: 0; position: relative; inset-block-start: -5px; inset-inline-start: -10px; }
.form-control label { line-height: 12px !important; block-size: 22px !important; }
.mobile-view { display: none; }
.project-content-3 { padding-block-end: 50px; }
.download-brochure-btn { 
   font-weight: 600 !important; border: 1px solid var(--clrSecondaryButton) !important; border-radius: 30px; }
.download-brochure-btn svg { display: inline-block; vertical-align: middle; color: #e62e29; }
.project-content-12,
.project-content-9 { background: #fff; }
.project-content-9 .project-video-section-grid { background: none !important; }
.project-content-9 { border-block-end: solid 1px #eee; }
.project-content-9 .project-video-section-grid-left { text-align: start !important; background: none !important; }
.project-content-9 .project-video-section-grid-right,
.project-content-9 .project-video-section-grid-left { padding: 30px; }
.project-content-9 .project-video-section-grid-left h2,
.project-content-9 .project-video-section-grid-right h2 { text-align: start !important; margin-block-end: 30px; color: #000 !important; }
.project-content-9 .project-video-section-grid-left .MuiContainer-root { padding: 0 !important; }
.project-content-9 .project-video-section-grid-left section { padding: 0 !important; }
.project-video-section-grid-right .form-layout-card { padding: 0 !important; }
.why-form { border-block-start: solid 1px #ccc; padding-block-start: 100px; }
.why-form .MuiContainer-root .MuiContainer-root { padding: 0 !important; }
.why-choose { margin-block-start: 30px; }
.why-choose-top { background: #ebf5ed; padding: 50px 0; }
.what-makes-bhoomatha-unique figure { margin: 0; padding: 0; block-size: 600px; inline-size: 100%; }
.what-makes-bhoomatha-unique figure img { inline-size: 100%; block-size: 100%; object-fit: cover; }
.what-makes-bhoomatha-unique .custom-list-li { margin-block-start: 50px; }
.what-makes-bhoomatha-unique .custom-list-li li { inline-size: 45%; display: inline-block; }
.py-70 { padding-block-start: 70px; padding-block-end: 70px; }
.row { inline-size: 100%; }
.why-about-company-inner { padding: 100px; }
.why-about-company { background: #ebf5ed; }
.why-about-company .statistics { background: none; padding: 0; }
.why-about-company .statistics h2,
.why-about-company .statistics .lead { display: none; }
.why-about-company .MuiContainer-root { padding-inline-start: 0 !important; padding-inline-end: 0 !important; }
.why-about-company .statistics-card { padding: 10px 5px; cursor: pointer; }
.statistics-item { border: 1px solid #ccc; padding: 20px 2px; }
.why-about-company .statistics-card h4.text-primary { color: #000 !important; }
.statistics-item:hover { background: #fff; box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 30px; }
.achievements-block { background: #fff; }
.achievements-item { display: flex; flex-direction: column; align-items: center; gap: 30px; transition: 0.3s; padding: 25px 15px; border-image: initial; border-radius: 16px; text-align: center; cursor: pointer; }
.achievements-item:hover { box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 30px; border-color: transparent; }
.achievements-item img { inline-size: 100px; block-size: 100px; display: block; transition: 0.8s; }
.achievements-item:hover img { transform: rotateY(360deg); }
.achievements-item h5 { margin-block-end: 2px; font-size: 1.125rem; font-weight: bold; color: #333; }
.achievement-img { display: flex; justify-content: center; align-items: center; }
.custom-card1 { margin-block-end: 30px; background-color: rgb(255, 255, 255); gap: 20px; transition: 0.3s; padding: 30px 20px; border-width: 1px; border-style: solid; border-color: rgb(228, 228, 228); border-image: initial; border-radius: 16px; cursor: pointer; }
.custom-card1:hover { box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 30px; }
.why-choose-bg { background: #ebf5ed; }
.Why-choose-vision-mision .custom-card1 img { max-inline-size: 100px; margin: 0 auto; display: block; }
.contact-page .form-layout-card { padding: 30px 0 0 0 !important; }
.contact-page .form-layout-card label { background: #f6ffef !important; }
.contact-page1 .form-layout-card label { background: #ffffff !important; }
.contact-page2 .form-layout-card label { background: #f6ffef !important; }
.height-650 { block-size: 650px !important; }
.width-800 { inline-size: 800px !important; }
.fullscreen { inline-size: 100%; block-size: 100vh; }
.banner { position: relative; }
.banner-text { display: flex; align-items: center; justify-content: center; block-size: 100%; }
.flourish-chart { max-inline-size: 1000px !important; margin: 0 auto; }
.MuiTabs-flexContainer { display: flex; justify-content: center; align-items: center; }
.loader21 { inline-size: 60px; aspect-ratio: 2; --_g: no-repeat radial-gradient(circle closest-side, #000 90%, #0000); background: var(--_g) 0% 50%, var(--_g) 50% 50%, var(--_g) 100% 50%; background-size: calc(100%/3) 50%; animation: l3 1s infinite linear; }

@keyframes l3 {
  20% { background-position: 0% 0%, 50% 50%, 100% 50%; }
  40% { background-position: 0% 100%, 50% 0%, 100% 50%; }
  60% { background-position: 0% 50%, 50% 100%, 100% 0%; }
  80% { background-position: 0% 50%, 50% 50%, 100% 100%; }
}

.bage-lists { position: relative; }
.bage-lists span { display: inline-block; margin: 0 10px; padding: 8px 20px; font-size: 12px; font-weight: bold; border-radius: 30px; background: #a4d876; color: #000; }
.upcoming-badge { background: green !important; color: #fff !important; }
.ongoing-badge { background: orange !important; }
.completed-badge { background: red !important; }
.about-content { text-align: center; }
.project-content-4 { border-block-start: solid 1px #ccc; padding-block-start: 70px; padding-block-end: 50px; }
.project-content-41 { border-block-start: solid 1px #ccc; padding-block-start: 70px; padding-block-end: 70px; border-block-end: solid 1px #ccc; }
.project-video-section iframe { max-inline-size: 800px; block-size: 600px !important; margin: 0 auto; display: block; border: solid 10px #e9e3e3; }
.col-md-8 { flex: 0 0 66.6666666667%; max-inline-size: 66.6666666667%; }
.col-md-7 { flex: 0 0 58.3333333333%; max-inline-size: 58.3333333333%; }
.col-md-5 { flex: 0 0 41.6666666667%; max-inline-size: 41.6666666667%; }
.stats-newblock { background: var(--clrPrimary); text-align: center; padding: 30px 0; color: #fff; }
.stats-newblock .row { margin: 0; }
.stats-newblock h3 { margin-block-end: 50px; }
.border-bottom { border-block-end: solid 1px #ffff; }
.sat-l1 { font-size: 60px; font-weight: 700; line-height: 60px; text-align: start; border-inline-end: solid 1px #ffff; margin-inline-end: 30px; margin-block-end: 0 !important; padding-block-end: 0; }
.sat-l1 span { font-size: 32px; line-height: 32px; display: block; font-weight: 400; }
.sat-l2 { font-size: 46px; font-weight: 700; line-height: 46px; text-align: start; margin-block-end: 0 !important; padding-block-end: 0; }
.sat-l2 span { font-size: 24px; line-height: 24px; display: block; font-weight: 400; }
.sat-l2 em { font-size: 18px; line-height: 18px; display: block; font-weight: 400; font-style: normal; }
.sat-b1 { font-size: 60px; font-weight: 700; line-height: 60px; text-align: start; border-inline-end: solid 1px #ffff; margin-inline-end: 30px; margin-block-end: 0 !important; padding-block-end: 0; }
.sat-b1 span { font-size: 32px; line-height: 32px; display: block; font-weight: 400; }
.sat-b2 { font-size: 46px; font-weight: 700; line-height: 46px; text-align: start; margin-block-end: 0 !important; padding-block-end: 0; }
.sat-b2 span { font-size: 24px; line-height: 24px; display: block; font-weight: 400; }
.sat-b2 em { font-size: 18px; line-height: 18px; display: block; font-weight: 400; font-style: normal; }
.MuiTabs-indicator { display: none !important; }
.why-choose-bhoomatha-np { padding-block-start: 0; }
.why-choose-bhoomatha-np .why-choose { margin-block-start: 0; }
.phone-section { padding: 10px 0; max-inline-size: 260px; }
.phone-section .btn-primary,
.phone-section .btn-secondary { font-size: 13px !important; padding: 5px 15px !important; margin: 0 !important; }
.phone-section .btn-primary svg,
.phone-section .btn-secondary svg { inline-size: 18px; block-size: 18px; display: inline-block; margin-inline-end: 10px; }
.phone-section .phone-number { background: none !important; padding: 0 !important; margin: 0 !important; font-size: 24px; color: #000 !important; }
.phone-section .phone-number svg { display: inline-block; margin-inline-end: 10px; }
.phone-section .phone-number:hover { color: var(--clrTextSecondary) !important; }
.project-video-baner { margin-block-start: -90px; }
.shadow-hover-efftect:hover{ box-shadow: 0 3px 15px #b7afaf !important;}
.border-radius-12{border-radius: 24px !important;}
.home-why-group .shadow-hover-efftect .btn-primary svg{position: relative; inset-inline-start: 0;}
.home-why-group .shadow-hover-efftect:hover .btn-primary svg{inset-inline-start: 10px;}
.slick-prev, .slick-next{z-index: 99 !important;}
.slick-prev{inset-inline-start: 10px !important;}
.slick-next{inset-inline-end: 10px !important;}
.project-gallery figure{margin: 0; block-size: 300px;}
.project-gallery figure.first-image{block-size: 626px;}
.project-gallery figure img{block-size: 100%; object-fit: cover; min-block-size: 500px;}
.banner-pic, .banner-pic img {border-radius: 18px;}
.qr-card{max-inline-size: 400px; margin: 0 auto;}
.top-animation{position: relative; inset-block-start: 0;}
.top-animation:hover{inset-block-start: -10px;}
.premium-features .features-card:hover{box-shadow: 0 3px 15px #b7afaf !important;}

.equal-heights .project-card{min-block-size: 680px;}

.nav li a:hover, .nav li a:hover span{background: none !important;     color: #e52e26 !important;}

.project-gallery1 figure{block-size: 100%; inline-size: 100%;}
.project-gallery1 figure img{object-fit: cover; block-size: 100%; inline-size: 100%;}

.main-title{position: relative; padding-block-end: 20px !important;}
.main-title:before{content: ""; position: absolute;  inset-inline-start: 0;  inset-block-end: 0; background: var(--clrPrimary); inline-size: 100%; block-size: 3px; max-inline-size: 90%;}
.btn-arrow-efftect {position: relative; display: inline-block;}
.btn-arrow-efftect svg{position: relative; inset-inline-start: 0;}

.btn-arrow-efftect:hover svg{inset-inline-start: 10px;}

.project-section .project-heading a{color: var(--clrBlack) !important;}
.contact-description{text-align: center;}