@media (min-width: 992px) {
.project-grid:nth-child(even) .project-image{-webkit-order: 2; -ms-flex-order: 2; order: 2; }
.project-grid:nth-child(even) .project-content{-webkit-order: 1; -ms-flex-order: 1; order: 1;}
header::before{inline-size: 100%;}
header{position: fixed; inset-inline-start: 0; inset-block-start: 0; inline-size: 100%; }
.home-banner{margin-block-start: 80px;}
.scrolling-down header{background: #fbfffa !important; box-shadow: 0px 0 35px #f1f1f1; z-index: 99;}
.scrolling-up header {background: #fbfffa !important;}
.scrolling-down header .logo{max-inline-size: 240px; padding: 10px 0;}
.scrolling-down .header-top{padding: 0px 0  5px 0;}
.scrolling-down header .nav{padding-block-start: 0 !important; padding-block-end: 0 !important;}
.scrolling-down header .nav li a,   .scrolling-down header .nav li a span{padding-block-start: 3px !important; padding-block-end: 3px !important;}
.scrolling-down header .header-inner{min-block-size: 50px;}
.scrolling-down header .logo::before {inline-size: 310px;}
.banner-pic{block-size: 800px;}
.banner-pic img{inline-size: 100%; block-size: 100%; object-fit: cover;}
.image-gallery figure{margin: 0; padding: 0;}
.image-gallery figure {block-size: 350px; overflow: hidden;}
.image-gallery figure img{block-size: 100%;}
.image-gallery figure.figure-left {block-size: 725px; }
.item-width{inline-size: 100px; block-size: 600px;}
.home-banner .slick-slide:nth-child(even) .slider-left{-webkit-order: 2 !important; -ms-flex-order: 2 !important; order: 2 !important;}
.property-image{ inline-size: 100%; block-size: auto; }
.project-section .features-block .row {justify-content: center; align-items: center; }
.project-banner { position: relative; padding: 0; inline-size: 100%; margin-block-start: 65px; }
.home-banner .slick-slide:nth-child(even) .slider-right{ -webkit-order: 1 !important; -ms-flex-order: 1 !important; order: 1 !important; }
.core-values{ display: flex; justify-content: center;}
.about-core{ display: flex; justify-content: center;}
.mobile-navigation{display: block !important;}
.mob-menu-button {display: none !important;}
.achieve-row{padding: 50px;}
.half-list li{display: inline-block; max-inline-size: 40%;}
.f-image-6 img{min-inline-size: 420px;}

}
@media (min-width: 1921px) {
.MuiContainer-root { padding-inline-start: 70px !important; padding-inline-end: 70px !important; }
.products-search .MuiContainer-root { padding-inline-start: 0px !important; padding-inline-end: 0px !important; }
.project-video-baner{    margin-block-start: -100px;}
}

@media (max-width: 1920px) {
.home-banner .text-inner h1, .project-heading { font-size: 40px !important; line-height: 42px !important; }
.home-banner .text-inner div, .home-banner .text-inner p { font-size: 18px; }
.welcome-content h2 { font-size: 50px !important; line-height: 56px !important; }
.welcome-content div, .welcome-content p { font-size: 16px !important; line-height: 24px !important; }
.welcome-pic .pic1 { margin-block-end: 50px; }
.image-gallery .lead { font-size: 16px !important; line-height: 24px !important; }
.nav li a, .nav li a span { font-size: 20px; padding: 4px 8px !important; font-weight: 600; }
header::after { border-block-start: 141px solid var(--clrTertiary); }
.statistics-ul li span { font-size: 13px; }
.statistics-ul li { font-size: 24px; }
.banner-pic { block-size: 700px; }
.welcome-pic .pic1 { inline-size: 80%; }
.slider-inner figure { block-size: 400px; }
.slider-inner .slick-center.slick-current figure { block-size: 550px; }
.btn-primary, .btn-secondary { font-size: 15px !important; padding: 9px 26px !important; }
.btn-lg { font-size: 18px !important; line-height: 24px !important; padding: 12px 32px !important; }
.header-right svg { display: inline-block; inline-size: 18px; block-size: 18px; margin-inline-end: 10px; }
.phone-section .phone-number { font-size: 22px; }
.why-choose-vision-mision .custom-card1{min-block-size: 250px;}
.why-choose-vision-mision .custom-card1 h4{margin-block-end: 5px;}
.why-choose-vision-mision .custom-card1 p{margin-block-end: 0 !important; padding-block-end: 0 !important;}
.why-choose-vision-mision .custom-card1 img{max-inline-size: 80px; display: block; margin: 0 auto;}
.why-choose-vision-mision .custom-card1 .mb-30{margin-block-end: 0 !important;}
.banner-pic img{max-inline-size: 96%; display: block; margin: 0 auto !important;}
.value-item p{font-size: 0.9rem !important;}
}

@media (max-width: 1700px) {
.page-content { padding: 0 30px; }
.statistics-ul li span { font-size: 12px; }
.statistics-ul li { padding: 0 30px; }
.nav li a, .nav li a span { font-size: 20px; padding: 8px 7px; }
.banner-pic { block-size: 600px; }
.main-serach .MuiContainer-root { max-inline-size: 1000px; }
.feat-carc-list .custom-card-content { margin: 10px; }
.core-values .MuiGrid2-grid-md-2 { inline-size: calc(100% * 2.4 / var(--Grid-parent-columns) - (var(--Grid-parent-columns) - 2.4) * (var(--Grid-parent-columnSpacing) / var(--Grid-parent-columns))); }
.core-values .value-item { min-block-size: 400px; padding: 10px; }
.f-image-4 .fan-animation { inline-size: 250px !important; block-size: 170px !important; }
.f-image-2 .cycle-animation { inline-size: 200px !important; block-size: 220px !important; inset-block-start: 28px; }
.tree-image-3 .tree-animation { inline-size: 50px !important; block-size: 92px !important; }
.tree-image-1 .tree-animation, .tree-image-4 .tree-animation { inline-size: 75px !important; block-size: 125px !important; }
.tree-image-2 .tree-animation { inline-size: 125px !important; block-size: 200px !important; }
body .f-image-4 .fan-animation { inline-size: 200px !important; block-size: 130px !important; }
.tree-image-3 .tree-animation { inline-size: 45px !important; block-size: 84px !important; }
header .nav li { margin: 0 15px; }
body .home-banner .text-inner h1{font-size: 40px !important; line-height: 44px !important; min-block-size: 80px;}
}

@media (max-width: 1500px) {
body .f-image-2 .cycle-animation { inline-size: 160px !important; block-size: 190px !important; inset-block-start: 28px; }
.nav li a, .nav li a span { font-size: 17px; padding: 8px 10px; }
.custom-block p { font-size: 14px; }
.custom-block .inner { padding: 50px !important; }
.social-icons a { inline-size: 30px; block-size: 30px; line-height: 40px; }
.project-list-left { block-size: auto; }
.nav li a, .nav li a span { font-size: 18px; padding: 4px 4px; }
.header-top .social-icons a svg { inset-block-start: -9px; }
.home-banner .text-inner h1, .project-heading, .welcome-content h2 { font-size: 40px !important; line-height: 42px !important; }
.home-banner .text-inner div, .home-banner .text-inner p { font-size: 18px; }
.welcome-content div, .welcome-content p { font-size: 16px !important; line-height: 26px !important; }
.project-grid .lead { font-size: 16px !important; line-height: 22px !important; }
.slider-inner figure { block-size: 400px; }
.slider-inner .slick-center.slick-current figure { block-size: 550px; }
.textimonal-inner p { font-size: 14px !important; }
header::after { border-block-start: 131px solid var(--clrTertiary); }
.project-section .features-block .shadow { min-block-size: 630px; }
.project-section1 .project-card { margin: 0 !important; }
.banner-pic { block-size: 500px; }
.logo { max-inline-size: 230px; padding: 10px 0; }
.logo::before { inline-size: 275px; }
.feat-carc-list .custom-card-content { margin: 15px 2px; padding: 30px !important; }
.btn-lg { font-size: 16px !important; line-height: 20px !important; padding: 10px 30px !important; }
.project-image-inner figure figcaption { padding: 8px 20px; font-size: 14px !important; }
.project-image-inner figure figcaption p { font-size: 14px !important; }
.slider-inner figure { block-size: 300px; }
.slider-inner .slick-center.slick-current figure { block-size: 430px; }
.image-gallery figure { block-size: 275px; }
.image-gallery figure.figure-left { block-size: 578px; }
.why-about-company-inner { min-inline-size: none; }
.core-values .MuiGrid2-grid-md-2 { inline-size: calc(100% * 4 / var(--Grid-parent-columns) - (var(--Grid-parent-columns) - 4) * (var(--Grid-parent-columnSpacing) / var(--Grid-parent-columns))); }
.core-values .value-item { min-block-size: 350px; padding: 10px; gap: 15px; }
.core-values .MuiContainer-root { padding: 0 150px; }
.core-values .row { justify-content: center; }
header .nav li { margin: 0 5px; }
.phone-section .phone-number { font-size: 20px; }
.why-choose-vision-mision .custom-card1{min-block-size: 280px;}
body .f-image-4 .fan-animation { inline-size: 150px !important; block-size: 100px !important; }
body .f-image-1 .w-100 {max-inline-size: 150px !important;}
.footer-inner .full-block img{max-inline-size: 80%; display: block; margin: 10px auto 0px auto;}

}

@media (max-width: 1300px) {
.nav li a, .nav li a span { font-size: 14px; padding: 4px 0px; }
.home-banner .text-inner h1, .project-heading, .welcome-content h2 { font-size: 32px !important; line-height: 36px !important; }
.our-feature-card .custom-card-content::before { inset-block-start: -30px; margin-inline-start: -30px; inline-size: 60px; block-size: 60px; }
.feature-pic { inline-size: 30px; inset-block-start: 35px; }
.our-feature-card .mt-30 { margin-block-start: 10px !important; }
.core-values .MuiContainer-root { padding: 0 50px; }
header.nav li a, header .nav li a span { font-size: 15px; }
.why-choose-vision-mision .custom-card1{min-block-size: 300px;}
}

@media (min-width: 995px) and (max-width: 1110px) {
.logo { max-inline-size: 200px; }
.nav li { margin: 0 10px; }
}

@media (max-width: 1200px) {
.web-view.screen1199 { display: none !important; }
.mobile-view.screen1199 { display: block !important; }
}

@media (max-width: 992px) {
.statistics-ul li { inline-size: 100%; }
.statistics-ul li { border-inline-end: 0; border-block-end: solid 1px #fff; padding-block-end: 30px; margin-block-end: 30px; }
.statistics-ul li span { font-size: 16px; }
.project-section-new { margin-block-end: 50px; padding-block-end: 30px; }
.project-section-new::before { block-size: 100%; }
.project-section-new .features-block { margin-block-start: 0 !important; }
.project-section .features-block img { block-size: auto !important; }
.project-section .features-block .shadow { min-block-size: 10px; }
.statistics-block { margin-block-end: 0; }
.custom-card-width { min-inline-size: 100%; margin-block-end: 20px; }
.project-section .features-block .row { justify-content: center; align-items: center; }
.property-image { inline-size: 100%; block-size: 450px; }
.play-explore figure { padding: 0; margin: 0; display: block; }
.mobile-hide { display: none !important; }
.mobile-show { display: block !important; }
header { position: relative; }
header .logo { max-inline-size: 180px; }
.home-banner { padding-block-start: 0; }
.banner-text { text-align: center; }
.banner-pic, .banner-pic img { border-radius: 0; }
.full-container { padding-inline-start: 0 !important; padding-inline-end: 0 !important; }
.home-banner .text-inner { padding: 10px; }
.home-banner .btn-lg { font-size: 15px !important; padding: 8px 28px !important; }
body .home-banner .text-inner h1 { font-size: 22px !important; line-height: 30px !important; min-block-size: 60px; }
body .home-banner .text-inner div, body .home-banner .text-inner p { font-size: 16px; }
.main-serach .search-title { position: relative; inset-inline-start: 0; inset-block-start: 0; padding: 0; color: var(--clrPrimary) !important; background: none; font-size: 24px !important; }
.main-serach .location-search { padding-block-start: 30px; }
.main-serach { margin: 25px 0 15px 0; }
section { padding: 3rem 0; }
.mob-text-center { text-align: center !important; }
.mob-px-15 { padding-inline-start: 15px; padding-inline-end: 15px; }
.mob-padding { padding: 20px !important; }
.mob-margin { margin: 0px !important; }
.shadow-pic::before { inset-inline-start: 8px; inset-block-start: 8px; box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .19) !important; }
h1 { font-size: 2.5rem !important; line-height: 2.5rem !important; }
h2 { font-size: 2rem !important; line-height: 2rem !important; }
.custom-block .inner { text-align: center; padding: 15px !important; }
.statistics { margin: 50px 0 0 0; }
.footer-inner { text-align: center; padding: 30px 0; }
.footer-logo { max-inline-size: 300px; margin: 0 auto 15px auto; }
.footer-logo img { inline-size: 100%; }
.footer-title { text-align: start !important; }
.social-icons a { inline-size: 30px; block-size: 30px; line-height: 39px; margin: 0 8px 15px 0; }
body .copyrights .copyinner { display: block; text-align: center; }
.copyinner p, .copyinner a { inline-size: 100%; display: block; }
.inner-banner .inner-text { padding: 10px 0; }
.aboutus h2 { text-align: center; }
.filter-block { margin-block-start: 30px; }
.mobile-footer { background: var(--clrWhite); inline-size: 100%; position: fixed !important; inset-inline-start: 0; inset-block-end: -2px; z-index: 99; text-align: center !important; box-shadow: 0px -3px 15px #7e7c7c; border-start-start-radius: 10px; border-start-end-radius: 10px; }
.mobile-footer { margin: 0 !important; padding: 0 !important; list-style: none; }
.mobile-footer li { display: inline-block; text-align: center; inline-size: 32% !important; }
.mobile-footer li a { font-size: 12px; font-weight: bold; color: #000 !important; }
.mobile-footer li svg { display: block; inline-size: 40px; block-size: 40px; background: var(--clrWhite); margin: 0 auto 5px auto; border-radius: 100%; padding: 8px; color: var(--clrPrimary); box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important; border: solid 1px #ccc; }
.footer-booknow svg { transform: rotate(180deg); }
body .nav li a, body .nav li a span { color: #000 !important; }
body .nav { margin: 0 !important; }
body .nav li { display: block; inline-size: 100%; border-block-end: solid 1px #ccc; padding: 0; margin: 0; }
body .nav li.calnow-button { margin-block-start: 50px !important; }
body .nav li.calnow-button a, body .nav li.calnow-button span { color: #fff !important; }
.project-card.m-30 { margin: 0 !important; }
.nri-list li { max-inline-size: 100%; }
.nri-content { position: relative; inset-inline-start: 11px; }
.project-grid:nth-child(even) figure { margin-inline-start: 0px !important; }
.statistics-item { padding: 10px 2px; }
.nri-content .nri-item h3, p, h5 { text-align: center; }
.nri-list li:last-child { max-inline-size: 100%; }
.features-list li { max-inline-size: 100%; }
.custom-row { margin: 20px 0px; }
.custom-list-li { margin: 0px 20px; }
.custom-list-li li { text-align: start; }
.core-values { padding-block-end: 20px; }
.whatsapp-button { inset-block-end: 25px !important; inset-inline-start: 10px !important; }
.tawk-min-container .tawk-button-circle.tawk-button-large { inline-size: 40px !important; block-size: 40px !important; }
.project-list-left { max-inline-size: 100% !important; }
.project-list-left .MuiTabs-flexContainer { flex-direction: row; overflow: scroll; }
.project-list-left button { font-size: 14px !important; text-transform: capitalize; }
.page-title-section { text-align: center; }
.page-title-section h1 { font-size: 30px !important; line-height: 34px !important; }
.MuiBreadcrumbs-ol { text-align: center; display: block !important; }
.MuiBreadcrumbs-ol li { display: inline-block !important; font-size: 12px !important; }
.project-details-left { box-shadow: none !important; }
.project-details-left .MuiCardContent-root { padding: 0 !important; box-shadow: none !important; }
.project-details-left h1 { font-size: 30px !important; text-align: center; }
.project-blocks .custom-card { margin-block-end: 0 !important; }
.custom-card.mob-mb-15 { margin-block-end: 15px !important; }
.sub-menu { position: relative !important; inline-size: 100% !important; }
.mobile-block { padding: 15px; text-align: center; }
.inner-section .custom-card { padding: 30px; text-align: center; }
.header-top { display: none !important; }
header::before { inline-size: 35%; }
header::after { border-block-start: 75px solid var(--clrTertiary); inset-inline-end: 35%; border-inline-start: 30px solid transparent; }
.project-banner { position: relative; padding: 0; inline-size: 100%; margin-block-start: 65px; }
.welcome-pic .pic1 { inline-size: 100%; margin-block-end: 100px; }
.welcome-pic .pic2 { inline-size: 50%; inset-block-end: -70px; inset-inline-end: 0; }
.welcome-content { text-align: center; }
.welcome-content div, .welcome-content p { font-size: 15px !important; line-height: 26px !important; }
.testimonial-section { position: relative; }
.feat-carc-list { margin-block-end: 10px; }
.project-content-inner { padding: 15px; }
.project-content-inner h2, .project-content-inner .text { text-align: center; }
.icon-with-text svg { margin-inline-end: 9px; inline-size: 20px; position: relative; inset-block-start: -2px; }
.icon-with-text p { font-size: 13px; }
.amenities-block ul li { inline-size: 100%; }
.icon-with-text { text-align: start !important; }
.statistics-card p { font-size: 14px; }
.slider-inner figure img { inline-size: 100%; }
.slider-inner figure { block-size: 300px; }
.custom-block .figure { padding: 30px; }
.custom-block .inner { background: none !important; padding-block-start: 0 !important; }
.textimonal-inner { padding: 0; background: none; }
.section-title h5 { font-size: 15px !important; margin-block-start: 15px; }
.blog-pic, .blog-pic img { block-size: auto; }
.blog-card .card-actions { text-align: center !important; }
body .foote-bg { inset-block-start: -10px; margin-block-end: 0; }
.copyinner p, .copyinner, .copyinner a { font-size: 12px; margin: 0 !important; padding: 0; }
.project-heading, .welcome-content h2 { font-size: 32px !important; line-height: 34px !important; }
.healthy-lifestyle { text-align: center; }
.healthy-lifestyle .features-block .row { padding: 0 15px 0 15px; }
.healthy-lifestyle .features-block .MuiGrid2-root { inline-size: 100%; }
.journey-of-hightlights .features-block figure { margin: 0; padding: 0; }
.journey-of-hightlights { text-align: center; }
.inner-section iframe { block-size: 300px !important; }
.inner-section.css-kx40rs { block-size: auto !important; }
.project-video-section-grid-right, .project-video-section-grid-left { padding: 30px 10px 10px 10px; }
.project-video-section-grid-right h2 { text-align: center; }
.project-section1 .MuiTabs-flexContainer button { font-size: 14px; }
.project-section1 .product-pic { block-size: auto !important; }
.tawk-min-container button { inset-inline-start: 28px !important; inset-block-end: 1px !important; }
.tawk-min-container .tawk-button-circle.tawk-button-large { inline-size: 40px; block-size: 40px; }
.banner-pic { block-size: auto !important; }
.slick-dots li, .slick-dots li button, .slick-dots li button:before { inline-size: 15px; block-size: 15px; font-size: 15px; }
.xour-feature-card { margin-block-start: 0px !important; }
.project-grid figure { block-size: auto; margin: 0; }
.why-about-company-inner { padding: 20px; }
.achievements-block { padding: 10px; }
.custom-padding, .nti-block { padding: 10px; }
.project-image-inner figure figcaption p { font-size: 11px !important; }
.project-image-inner figure figcaption { padding: 5px 12px; font-size: 11px !important; inset-inline-start: 22px; }
.project-slider { padding: 50px 0 90px 0; }
.custom-block .figure, .custom-block .lead { inline-size: 100%; }
.custom-block .figure { block-size: 400px; padding: 0; }
.image-gallery { text-align: center; padding: 15px 0; }
.testimonial-quote svg { inline-size: 50px; block-size: 50px; }
.testimonial-section { padding-block-end: 100px; }
body #chb9ao2dducg1739428782980.widget-visible, body .widget-visible { inset-block-end: 100% !important; inset-block-start: auto !important; position: fixed !important; margin-block-end: 100px !important; }
.inner-banner { padding: 0; margin: 0; }
.inner-banner .section-content { display: none; }
.web-view { display: none; }
.mob-view { display: block; margin: 30px 0; }
.inner-text { position: relative; }
.enter-location-button { position: absolute !important; inset-inline-start: 0; inset-block-end: 0; font-size: 12px !important; color: #fff !important; inline-size: 70px !important; display: block !important; }
.enter-location-button svg { display: block !important; margin: 0 auto; }
.inner-banner .web-view { display: none; }
.search-result-page { margin: 10px 0px !important; }
.mobile-view { display: block; }
.custom-icons { text-align: center; }
.custom-icons button { display: inline-block; inline-size: 22%; font-size: 10px; line-height: 12px; margin: 0 5px; padding: 0 !important; background: none !important; color: #000 !important; vertical-align: top; }
.custom-icons button svg { inline-size: 30px; block-size: 30px; display: block; margin: 0 auto 5px auto; color: #529211; }
.project-content-1, .project-content-5 { padding: 15px 0; }
.project-content-5 .statistics-ul { text-align: start !important; }
.project-content-5 .statistics-ul li { inline-size: 42%; display: inline-block; border: solid 1px #ccc; margin: 0 10px 10px 10px; text-align: center !important; min-block-size: 100px; text-shadow: none !important; border-radius: 12px; padding: 15px; font-size: 18px; line-height: 22px; }
.project-content-5 .statistics-ul li span { font-weight: 400 !important; font-size: 14px; line-height: 18px; display: block; margin-block-start: 12px; }
.project-content-5 .statistics-ul li:last-child { inline-size: 90%; margin: 0 0 30px 10px; }
.project-content-14 h5 { margin: 0 30px; }
.project-content-6 { padding-block-start: 0px; }
.project-content-14 section { padding-block-end: 0 !important; }
.project-content-7 { padding: 0; }
.project-content-7 .features-block { margin-block-start: 0 !important; }
.project-content-7.journey-of-hightlights .features-block h2 { padding: 0; text-align: center; margin: 0 0 30px 0; }
.project-content-7.journey-of-hightlights .features-block ul li { text-align: start; }
.features-block span { font-size: 13px; }
body { inline-size: 100%; overflow-y: auto !important; overflow-x: hidden !important; }
.aboutus { padding: 0 15px; text-align: center; }
.image-gallery { display: block; margin: 0; }
.vision-mission { padding: 15px 0; }
.about-company { inline-size: 100%; }
.choose p { text-align: center; margin: 0; padding: 0; }
.features-list li { inline-size: 92%; }
.scrolling-up .home-banner, .header-space { margin-block-start: 113px; }
.why-choose-bhoomatha-group { margin-block-start: -80px !important; }
.scrolling-up .why-choose-bhoomatha-group { margin-block-start: 0px !important; }
.search-city { padding-block-start: 8px; }
.scrolling-up header { position: fixed !important; inset-inline-start: 0 !important; inset-block-start: 0 !important; inline-size: 100% !important; }
.image-gallery figure.figure-left, .image-gallery figure, .image-gallery figure.figure-left img, .image-gallery figure img { block-size: auto; padding: 0; margin: 0; }
.MuiMasonry-root .MuiPaper-elevation1 { block-size: auto !important; }
.pswp img { max-inline-size: 100% !important; max-block-size: 90% !important; position: relative !important; inset-block-start: 70px !important; block-size: auto !important; }
.mob-accordion .MuiPaper-root { background: none !important; margin-block-end: 30px; }
.mob-accordion li { border-block-end: solid 1px #ccc; }
.mob-accordion li:last-child { border: none; }
.mob-accordion li, .mob-accordion li a { color: #000 !important; text-align: start !important; }
.mob-accordion .text-white { color: #000 !important; }
.footer-list li a { padding-inline-start: 0; }
.why-choose-top { padding: 30px 0; }
.what-makes-bhoomatha-unique figure, .what-makes-bhoomatha-unique figure img { block-size: auto; }
.what-makes-bhoomatha-unique .custom-list-li li { inline-size: 100%; }
.core-values .MuiGrid2-grid-sm-6 { inline-size: 100%; }
.core-values .MuiContainer-root { padding: 0 20px; }
.core-values { padding-block-end: 10px; padding-block-end: 0px !important; justify-content: center; display: flex !important; }
.why-about-company { padding: 20px; }
.why-about-company-inner { text-align: center; }
.why-about-company .MuiGrid2-grid-sm-4 { inline-size: 100% !important; }
.custom-card1 { text-align: center; }
.py-70 { padding: 30px 0; }
.css-1xqkkzg-MuiGrid2-root { inline-size: 100%; }
.mobile-navigation { display: none !important; }
.mob-menu-button { display: block !important; margin: 0 !important; }
.MuiDrawer-root { display: block !important; }
.vision-mission::before, .vision-mission::after { display: none; }
.scrolling-up .inner-banner { margin-block-start: 113px; }
body .core-values .MuiGrid2-grid-md-2 { inline-size: 100% !important; }
.why-choose-bhoomatha-group { inline-size: 100%; overflow-x: hidden !important; overflow-y: auto !important; }
.features-block .row { padding: 0px 0px 0px 20px; }
.why-about-company h2 { text-align: center !important; }
.custom-card111 { margin: 0; padding: 0; text-align: center; }
.custom-card111 .row { margin: 0; }
.custom-card111 .nri-img { border-radius: 0 !important; }
.custom-card111 .achieve-row:nth-child(even) .MuiGrid2-grid-md-6:first-child { -webkit-order: 2 !important; -ms-flex-order: 2 !important; order: 2 !important; }
.custom-card111 .achieve-row:nth-child(even) .MuiGrid2-grid-md-6:last-child { -webkit-order: 1 !important; -ms-flex-order: 1 !important; order: 1 !important; }
.project-section { padding-block-end: 0 !important; }
.agents-sections .section-title { padding: 20px 0 0 0 !important; margin: 0 !important; }
.web-view { display: none !important; }
.mobile-view { display: block !important; }
.header-top { background: #f8c75c; text-align: center; }
.header-top a { font-size: 12px; padding: 0 !important; margin: 0 9px !important; display: inline-block !important; color: #000 !important; }
.header-top a svg { inline-size: 20px; block-size: 20px; margin-inline-end: 3px; display: inline-block; vertical-align: middle; color: var(--variant-textColor); position: relative; inset-block-start: -1px; }
body .home-banner { padding: 0; }
.why-group { text-align: center; }
body .footer .social-icons a { inline-size: 25px !important; block-size: 25px !important; }
body .footer .social-icons a svg { inline-size: 15px !important; block-size: 15px !important; position: relative; inset-block-start: -10px; }
.project-content-1 .property-image { block-size: auto !important; }
.scrolling-down .project-content-1, .scrolling-up .project-content-1 { margin-block-start: 130px; }
body h4 { font-size: 24px !important; line-height: 24px; }
body .project-gallery img { inline-size: 100% !important; block-size: auto !important; }
body .home-banner { max-inline-size: 100%; overflow: hidden; }
.p-left, .p-right { padding: 0; }
.home-why-group,     .home-why-group h2{text-align: center !important;}
.home-why-group .p-50{padding: 30px 15px !important;}
.home-why-group .shadow-hover-efftect .btn-primary {font-size: 12px !important;}
body, .scrolling-up, .scrolling-down{overflow-x: hidden !important; overflow-y: auto !important;}
.aboutus .row{margin: 0;}
.product-pic, .product-pic img{block-size: auto !important;}
.mobile-custom-dialog h2{font-size: 20px !important;}
.mobile-custom-dialog .custom-check-box{display: block; inline-size: 100%; text-align: center;}
.row{margin: 0;}
.home-banner .slick-prev,    .home-banner .slick-next{inset-block-start: 200px !important;}
.custom-card111 {padding-block-start: 0 !important; margin-block-start: 0 !important;}
.inner-section{padding-block-start: 30px; padding-block-end: 30px;}
.equal-heights .project-card{min-block-size: 1px;}
.scrolling-up .project-video-baner, .scrolling-down .project-video-baner {margin-block-start:15px}
.scrolling-up .home-banner, .scrolling-down .home-banner {margin-block-start:15px}
}
